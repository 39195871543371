export enum AppRole {
  USER = 'USER',
  SUPER_USER = 'SUPER_USER',
}

export const normalizeAppRoles = (roles: string[]): AppRole[] => {
  return roles.map((r) => {
    switch (r.toLowerCase()) {
      case 'user':
        return AppRole.USER;
      case 'super user':
      case 'super_user':
        return AppRole.SUPER_USER;
      default:
        throw new Error('App role not implemented');
    }
  });
};
