import { User } from './models';
import { Permission } from './permissions';
import { policy } from './policy';

export const canForAccount = (
  user: User,
  accountId: string,
  permission: Permission,
): boolean => {
  return (
    user?.accountRoles.some(
      (ar) =>
        ar.accountId === accountId && policy[ar.role].includes(permission),
    ) || false
  );
};

export const canForAnyAccount = (
  user: User,
  permission: Permission,
): boolean => {
  return (
    user?.accountRoles.some((ar) => policy[ar.role].includes(permission)) ||
    false
  );
};
