export enum AccountRole {
  VIEWER = 'VIEWER',
  DRIVER = 'DRIVER',
  USER = 'USER',
  ADMINISTRATOR = 'ADMINISTRATOR',
}

export const normalizeAccountRoles = (
  roles: Array<{ accountId: string; role: string }>,
): Array<{ accountId: string; role: AccountRole }> => {
  return roles.map((r) => {
    switch (r.role.toLowerCase()) {
      case 'viewer':
        return { accountId: r.accountId, role: AccountRole.VIEWER };
      case 'driver':
        return { accountId: r.accountId, role: AccountRole.DRIVER };
      case 'user':
        return { accountId: r.accountId, role: AccountRole.USER };
      case 'administrator':
        return { accountId: r.accountId, role: AccountRole.ADMINISTRATOR };
      default:
        throw new Error('Account role not implemented');
    }
  });
};
