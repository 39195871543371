'use client';

import { ReactNode } from 'react';

import NextAdapterApp from 'next-query-params/app';
import { QueryParamProvider } from 'use-query-params';

import ApolloProvider from './apollo';
import AuthProvider from './auth';
import StatsigProvider from './statsig';
import { UserProvider } from './user';

export type ProvidersProps = {
  children: ReactNode;
};

const Providers: React.FunctionComponent<ProvidersProps> = ({ children }) => {
  return (
    <QueryParamProvider
      adapter={NextAdapterApp}
      options={{ enableBatching: true }}
    >
      <AuthProvider>
        <ApolloProvider>
          <StatsigProvider>
            <UserProvider>{children}</UserProvider>
          </StatsigProvider>
        </ApolloProvider>
      </AuthProvider>
    </QueryParamProvider>
  );
};

export default Providers;
