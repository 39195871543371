import(/* webpackMode: "eager" */ "/home/runner/work/client_wastewizer_com/client_wastewizer_com/apps/client-app/src/app/_components/DeployRefreshManager/DeployRefreshManager.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client_wastewizer_com/client_wastewizer_com/apps/client-app/src/app/_lib/providers/providers.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client_wastewizer_com/client_wastewizer_com/apps/client-app/src/app/global.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client_wastewizer_com/client_wastewizer_com/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client_wastewizer_com/client_wastewizer_com/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client_wastewizer_com/client_wastewizer_com/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
