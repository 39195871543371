import { roundWeight } from '@wastewizer/weight-utils';

import { WeightUnit } from '#generated-types';

export const convertWeightForDisplay = (
  weight: number | null | undefined,
  unit: WeightUnit,
  roundToNearest = false,
): number | null => {
  if (weight === null || weight === undefined) {
    return null;
  }

  switch (unit) {
    case WeightUnit.Kilogram:
      return roundWeight(weight, 0, roundToNearest ? 100 : undefined);
    case WeightUnit.Pound:
      return roundWeight(weight, 0, roundToNearest ? 200 : undefined);
    default:
      return roundWeight(weight, 1);
  }
};
