// node_modules/@carbon/grid/scss/_config.scss

const baseFontSize = 16;

export const breakPoints = {
  sm: 320 / baseFontSize,
  md: 672 / baseFontSize,
  lg: 1056 / baseFontSize,
  xlg: 1312 / baseFontSize,
  max: 1584 / baseFontSize,
};
