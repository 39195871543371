import { ReactNode } from 'react';

import { StatsigProvider, useClientAsyncInit } from '@statsig/react-bindings';

type StatsigProviderProps = {
  children: ReactNode;
};

const WrappedStatsigProvider: React.FunctionComponent<StatsigProviderProps> = ({
  children,
}) => {
  const { client } = useClientAsyncInit(
    process.env.NEXT_PUBLIC_STATSIG_API_KEY,
    {
      userID: 'anonymous',
    },
    {
      environment: { tier: process.env.NEXT_PUBLIC_STATSIG_ENVIRONMENT },
    },
  );

  return <StatsigProvider client={client}>{children}</StatsigProvider>;
};

export default WrappedStatsigProvider;
