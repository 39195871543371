import * as Types from '../../../../_generated-types/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const TrackTokenExchangeDocument = gql`
  mutation TrackTokenExchange {
    createUserEvent(input: { event: TOKEN_EXCHANGE }) {
      id
    }
  }
`;
export type TrackTokenExchangeMutationFn = Apollo.MutationFunction<
  TrackTokenExchangeMutation,
  TrackTokenExchangeMutationVariables
>;

/**
 * __useTrackTokenExchangeMutation__
 *
 * To run a mutation, you first call `useTrackTokenExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackTokenExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackTokenExchangeMutation, { data, loading, error }] = useTrackTokenExchangeMutation({
 *   variables: {
 *   },
 * });
 */
export function useTrackTokenExchangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrackTokenExchangeMutation,
    TrackTokenExchangeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TrackTokenExchangeMutation,
    TrackTokenExchangeMutationVariables
  >(TrackTokenExchangeDocument, options);
}
export type TrackTokenExchangeMutationHookResult = ReturnType<
  typeof useTrackTokenExchangeMutation
>;
export type TrackTokenExchangeMutationResult =
  Apollo.MutationResult<TrackTokenExchangeMutation>;
export type TrackTokenExchangeMutationOptions = Apollo.BaseMutationOptions<
  TrackTokenExchangeMutation,
  TrackTokenExchangeMutationVariables
>;
export type TrackTokenExchangeMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type TrackTokenExchangeMutation = {
  __typename?: 'Mutation';
  createUserEvent: { __typename?: 'UserEvent'; id: string };
};
